import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { verify } from './server-hooks';

const ADDRESS = 'address';
const VERIFICATION_CODE = 'code';

function Verification() {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const address = params.get(ADDRESS);
    const code = params.get(VERIFICATION_CODE);
    if (!code || !address) {
      return;
    }

    const verifyFn = async () => {
      await verify(address, code);
      navigate({
        pathname: '/',
        search: `?address=${address}`
      });
    };

    verifyFn();
  }, [params, navigate]);
  
  return (
    <CircularProgress color='info'/>
  );
}

export default Verification;
