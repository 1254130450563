import { createTheme } from '@mui/material/styles';

const THEME = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7f87ff',
    },
    secondary: {
      main: '#edf2ff',
    },
    info: {
      main: '#fff'
    }
  },
});

export default THEME;
