import { useEffect, useState } from 'react';

export enum PhoneModel {
  IPHONE_14 = 'IPHONE_14',
  IPHONE_14_MAX = 'IPHONE_14_MAX',
  IPHONE_14_PRO = 'IPHONE_14_PRO',
  IPHONE_14_PRO_MAX = 'IPHONE_14_PRO_MAX'
}
export enum ContactType {
  SMS = 'SMS',
  EMAIL = 'EMAIL'
}
export enum PhoneColor {
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  MIDNIGHT = 'MIDNIGHT',
  STARLIGHT = 'STARLIGHT',
  RED = 'RED',
  DEEP_PURPLE = 'DEEP_PURPLE',
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  SPACE_BLACK = 'SPACE_BLACK'
}

export interface NotificationConfig {
  product: {
    color: PhoneColor;
    model: PhoneModel;
    storage: number;
  };
  contactType: ContactType;
  contactAddress: string;
  address: string;
}

const URL_BASE = 'https://api.iphoneinventory.com'

export const enableNotifications = async (notificationConfig: NotificationConfig) => {
  await fetch(`${URL_BASE}/enable-notification`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(notificationConfig)
  });
};

export const disableNotifications = async (id: string, type: string) => {
  await fetch(`${URL_BASE}/disable-notification`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      id, type
    })
  });
};

export const verify = async (contactAddress: string, code: string) => {
  await fetch(`${URL_BASE}/verify`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      verificationCode: code,
      contactAddress
    })
  });
};

export const useNotificationConfig = (id: string, type: ContactType) => {
  const [notificationConfig, setNotificationConfig] = useState<any>(undefined);

  useEffect(() => {
    const fetchNotifications = async () => {
      const url = `${URL_BASE}/notifications?${type === ContactType.SMS ? 'sms' : 'email'}=${id}`;
      const notificationConfig = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json;charset=UTF-8'
        }
      });

      const json = await notificationConfig.json();
      if (!json.length) {
        setNotificationConfig(null);
      } else {
        setNotificationConfig(json);
      }
    };

    fetchNotifications();
  }, [id, type]);

  return notificationConfig;
};
