import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as MuiIcons from '@mui/icons-material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ContactType, disableNotifications, enableNotifications, NotificationConfig, PhoneColor, PhoneModel, useNotificationConfig } from './server-hooks';
import { useSearchParams } from 'react-router-dom';

const Divider = styled(({
  direction,
  className,
  size
}: { 
  direction: 'h' | 'v',
  className?: string,
  size: number
}) => {
  return (
    <div className={className}></div>
  );
})
`
  border-image: linear-gradient(to ${({ direction }) => direction === 'h' ? 'right' : 'bottom'}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
  border-${({ direction }) => direction === 'h' ? 'bottom' : 'right'}: 1px solid; 
  ${({ direction, size }) => {
    if (direction === 'h') {
      return `width: ${size}px`;
    } else {
      return `height: ${size}px`;
    }
  }}
`;

const Flex = styled(({ direction, spacing, style, className, children }: { direction: 'row' | 'column', spacing?: number, style?: React.CSSProperties, className?: string, children: React.ReactNode }) => {
  return (
    <div className={className} style={style}>{ children }</div>
  );
})
`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: center;

  ${({ spacing }) => {
    if (spacing) {
      return `gap: ${spacing}px`
    } else {
      return '';
    }
  }}
`;

const Choice = styled.button`
  width: 150px;
  height: 150px;
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const ConfigurationStep = styled.article`
  color: white;
`;

const Step = styled(({ description, body, className, onSubmit }: { description: string, body: React.ReactNode, className?: string, onSubmit?: () => void }) => {
  const ifEnterPressed = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  }, [onSubmit]);

  return (
    <div className={className} onKeyUp={ifEnterPressed}>
      <h2>{ description }</h2>
      { body }
    </div>
  );
})
`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-weight: 300;
    color: white;
    text-align: center;
  }
`;

const ChannelInput = styled(({ value, icon, label, className, error, errorText, onChange, onBlur }: { value: string | undefined, icon: string, label: string, className?: string, error?: boolean, errorText?: string, onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void, onBlur: () => void}) => {
  const Icon = MuiIcons[icon as keyof typeof MuiIcons];

  return (
    <div className={className}>
      <Flex direction='column' spacing={10}>
        <span>{ label }</span>
        <Icon style={{ fontSize: '100px' }} />
        <Flex direction='row' spacing={4}>
          <TextField 
            value={value || ''} 
            variant='filled' 
            onChange={onChange}
            error={error}
            helperText={errorText}
            onBlur={onBlur}
          />
        </Flex>
      </Flex>
    </div>
  );
})`
  transition: all 0.2s ease; 
  ${({ value }) => value === '' ? 'opacity: 0.5;' : ''}
`;

const PHONE_NUMBER_REGEX = /^\d{10,11}$/;
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
function EnterContactStep({ onNotificationTypeSpecified }: { onNotificationTypeSpecified: (notificationTypeInfo: { type: ContactType, id: string }) => void }) {
  const theme = useTheme();
  const regularScreenSize = useMediaQuery(theme.breakpoints.up('sm'));

  const [sms, setSms] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  const notificationInfo = useMemo(() => {
    if (sms) {
      return {
        type: ContactType.SMS,
        id: sms!
      };
    } else {
      return {
        type: ContactType.EMAIL,
        id: email!
      };
    }
  }, [sms, email]);

  const cleanPhoneNumber = useCallback((phoneNumber: string) => {
    return phoneNumber
      .replaceAll(' ', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('+', '')
      .replaceAll('-', '')
    ;
  }, []);

  const notificationInfoValid = useMemo(() => {
    if (sms) {
      return PHONE_NUMBER_REGEX.test(cleanPhoneNumber(sms));
    } else if (email) {
      return EMAIL_REGEX.test(email);
    }

    return false;
  }, [cleanPhoneNumber, sms, email]);

  const standardizeContactAddress = useCallback(() => {
    let addr = notificationInfo.id;
    if (notificationInfo.type === ContactType.SMS && notificationInfo.id.length < 11) {
      addr = '1' + addr;
    }

    onNotificationTypeSpecified({
      id: addr,
      type: notificationInfo.type
    });
  }, [notificationInfo, onNotificationTypeSpecified]);

  return (
    <Step 
      description='Enter a phone number or email address to configure notifications'
      body={
        <Flex direction='column' spacing={10}>
          <Flex direction={regularScreenSize ? 'row' : 'column'} spacing={16}>
            <ChannelInput
              value={sms}
              icon='Sms'
              label='SMS'
              error={!!(showError && sms)}
              errorText={!!(showError && sms) ? 'Invalid phone number' : undefined}
              onChange={(e) => {
                setSms(e.target.value);
                setEmail('')
              }} 
              onBlur={() => setShowError(!notificationInfoValid)}
            />
            <Divider
              direction={regularScreenSize ? 'v' : 'h'}
              size={200}
            />
            <ChannelInput 
              value={email}
              icon='Email'
              label='Email'
              error={!!(showError && email)}
              errorText={!!(showError && email) ? 'Invalid email address' : undefined}
              onChange={(e) => {
                setEmail(e.target.value);
                setSms('')
              }}
              onBlur={() => setShowError(!notificationInfoValid)}
            />
          </Flex>
          <IconButton disabled={!notificationInfo || !notificationInfoValid} onClick={standardizeContactAddress}>
            <ArrowForwardIcon />
          </IconButton>
        </Flex>
      }
      onSubmit={() => {
        if (!notificationInfo || !notificationInfoValid) {
          return;
        }
        standardizeContactAddress();
      }}
    />
  );
}

function getPhoneModelLabel(model: PhoneModel): string {
  switch (model) {
    case PhoneModel.IPHONE_14:
      return 'iPhone 14';
    case PhoneModel.IPHONE_14_MAX:
      return 'iPhone 14 Max';
    case PhoneModel.IPHONE_14_PRO:
      return 'iPhone 14 Pro';
    case PhoneModel.IPHONE_14_PRO_MAX:
      return 'iPhone 14 Pro Max';
    default:
      return 'Unknown';
  }
}

function getPhoneColorLabel(color: PhoneColor): string {
  switch (color) {
    case PhoneColor.BLUE:
      return 'Blue';
    case PhoneColor.PURPLE:
      return 'Purple';
    case PhoneColor.MIDNIGHT:
      return 'Midnight';
    case PhoneColor.STARLIGHT:
      return 'Starlight';
    case PhoneColor.RED:
      return 'Red';
    case PhoneColor.DEEP_PURPLE:
      return 'Deep Purple';
    case PhoneColor.GOLD:
      return 'Gold';
    case PhoneColor.SILVER:
      return 'Silver';
    case PhoneColor.SPACE_BLACK:
      return 'Space Black';
    default:
      return 'Unknown';
  }
}

function formatContactAddress(address: string, type: ContactType) {
  if (type === ContactType.SMS) {
    return `(${address.slice(1, 4)}) ${address.slice(4, 7)}-${address.slice(7)}`;
  } else {
    return address;
  }
}

function EditExistingStep({ 
  contactAddress,
  type,
  onExistingNotFound
}: {
  contactAddress: string,
  type: ContactType,
  onExistingNotFound: () => void
}) {
  const notificationState = useNotificationConfig(contactAddress, type);

  useEffect(() => {
    if (notificationState === null) {
      onExistingNotFound();
    }
  }, [notificationState, onExistingNotFound]);

  if (!notificationState) {
    return (
      <CircularProgress color='info'/>
    );
  }

  return (
    <Step 
      description='An existing notification is configured:'
      body={
        <>
          <table style={{ marginBottom: '1rem' }}>
            <tbody>
              <tr>
                <td align='right' style={{ paddingRight: 8 }}>Model</td>
                <td>{ getPhoneModelLabel(notificationState[0].Item.product.model) }</td>
              </tr>
              <tr>
                <td align='right' style={{ paddingRight: 8 }}>Color</td>
                <td>{ getPhoneColorLabel(notificationState[0].Item.product.color) }</td>
              </tr>
              <tr>
                <td align='right' style={{ paddingRight: 8 }}>Contact</td>
                <td>{ formatContactAddress(contactAddress, type) }</td>
              </tr>
            </tbody>
          </table>
          <Button variant="contained" onClick={() => disableNotifications(contactAddress, type)}>Delete</Button>
        </>
      }
    />
  );
}

function ChooseModelStep({ onModelSelected }: { onModelSelected: (model: PhoneModel) => void }) {
  const theme = useTheme();
  const regularScreenSize = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Step 
      description='Choose your desired model'
      body={
        <Flex direction={regularScreenSize ? 'row' : 'column' } spacing={16}>
          <Choice onClick={() => onModelSelected(PhoneModel.IPHONE_14)}>
            <PhoneIphoneIcon style={{ fontSize: '50px' }} />
            iPhone 14
          </Choice>
          <Divider
            direction={regularScreenSize ? 'v' : 'h'}
            size={200}
          />
          <Choice onClick={() => onModelSelected(PhoneModel.IPHONE_14_MAX)}>
            <PhoneIphoneIcon style={{ fontSize: '50px' }} />
            iPhone 14 Max
          </Choice>
          <Divider
            direction={regularScreenSize ? 'v' : 'h'}
            size={200}
          />
          <Choice onClick={() => onModelSelected(PhoneModel.IPHONE_14_PRO)}>
            <PhoneIphoneIcon style={{ fontSize: '50px' }} />
            iPhone 14 Pro
          </Choice>
          <Divider
            direction={regularScreenSize ? 'v' : 'h'}
            size={200}
          />
          <Choice onClick={() => onModelSelected(PhoneModel.IPHONE_14_PRO_MAX)}>
            <PhoneIphoneIcon style={{ fontSize: '50px' }} />
            iPhone 14 Pro Max
          </Choice>
        </Flex>
      }
    />
  )
}

function ChooseColorStep({ model, onColorSelected }: { model: PhoneModel, onColorSelected: (color: PhoneColor) => void }) {
  const theme = useTheme();
  const regularScreenSize = useMediaQuery(theme.breakpoints.up('sm'));

  const colors = useMemo(() => {
    switch (model) {
      case PhoneModel.IPHONE_14: 
      case PhoneModel.IPHONE_14_MAX:
        return [
          PhoneColor.BLUE,
          PhoneColor.PURPLE,
          PhoneColor.MIDNIGHT,
          PhoneColor.STARLIGHT,
          PhoneColor.RED
        ];
      case PhoneModel.IPHONE_14_PRO:
      case PhoneModel.IPHONE_14_PRO_MAX:
        return [
          PhoneColor.DEEP_PURPLE,
          PhoneColor.SILVER,
          PhoneColor.GOLD,
          PhoneColor.SPACE_BLACK
        ];
    }
  }, [model]);

  const colorNames = {
    [PhoneColor.BLUE]: 'Blue',
    [PhoneColor.PURPLE]: 'Purple',
    [PhoneColor.MIDNIGHT]: 'Midnight',
    [PhoneColor.STARLIGHT]: 'Starlight',
    [PhoneColor.RED]: 'Red',
    [PhoneColor.DEEP_PURPLE]: 'Deep Purple',
    [PhoneColor.SILVER]: 'Silver',
    [PhoneColor.GOLD]: 'Gold',
    [PhoneColor.SPACE_BLACK]: 'Space Black',
  };

  return (
    <Step 
      description='Choose your desired color'
      body={
        <Flex direction={regularScreenSize ? 'row' : 'column' } spacing={16}>
          {
            colors.map((color, index) => {
              return (
                <React.Fragment key={colorNames[color]}>
                  <Choice onClick={() => onColorSelected(color)}>
                    { colorNames[color] }
                  </Choice>
                  {
                    index < colors.length - 1 &&
                    <Divider
                      direction={regularScreenSize ? 'v' : 'h'}
                      size={200}
                    />
                  }
                </React.Fragment>
              );
            })
          }
        </Flex>
      }
    />
  );
}

function ChooseStorageStep({ model, onStorageSelected }: { model: PhoneModel, onStorageSelected: (storage: number) => void }) {
  const theme = useTheme();
  const regularScreenSize = useMediaQuery(theme.breakpoints.up('sm'));

  const storageOptions = useMemo(() => {
    switch (model) {
      case PhoneModel.IPHONE_14:
      case PhoneModel.IPHONE_14_MAX:
        return [128, 256, 512];
      case PhoneModel.IPHONE_14_PRO:
      case PhoneModel.IPHONE_14_PRO_MAX:
        return [128, 256, 512, 1024];
    }
  }, [model]);

  const storageLabels = new Map();
  storageLabels.set(128, '128 GB');
  storageLabels.set(256, '256 GB');
  storageLabels.set(512, '512 GB');
  storageLabels.set(1024, '1 TB');

  return (
    <Step 
      description='Choose your desired storage'
      body={
        <Flex direction={regularScreenSize ? 'row' : 'column' } spacing={16}>
          {
            storageOptions.map((storageOption, index) => {
              return (
                <React.Fragment key={storageLabels.get(storageOption)}>
                  <Choice onClick={() => onStorageSelected(storageOption)}>
                    { storageLabels.get(storageOption) }
                  </Choice>
                  {
                    index < storageOptions.length - 1 &&
                    <Divider
                      direction={regularScreenSize ? 'v' : 'h'}
                      size={200}
                    />
                  }
                </React.Fragment>
              );
            })
          }
        </Flex>
      }
    />
  );
}

const ZIP_REGEX = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
const POSTAL_CODE_REGEX = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
function EnterAddressStep({ onAddressEntered }: { onAddressEntered: (address: string) => void }) {
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('USA');
  const [showAddressValid, setShowAddressValid] = useState(false);

  const cleanAddress = (address: string) => {
    return address
      .replaceAll(' ', '')
      .replaceAll('-', '')
    ;
  };

  const addressValid = useMemo(() => {
    if (country === 'USA') {
      return ZIP_REGEX.test(cleanAddress(address));
    } else {
      return POSTAL_CODE_REGEX.test(cleanAddress(address));
    }
  }, [address, country]);

  return (
    <Step 
      description='Please enter your address so that we can find the nearest Apple store'
      body={
        <Flex direction='row' spacing={10}>
          <Select
            value={country}
            label="Country"
            onChange={(e) => setCountry(e.target.value)}
          >
            <MenuItem value={'CANADA'}>🇨🇦 Canada</MenuItem>
            <MenuItem value={'USA'}>🇺🇸 United States</MenuItem>
          </Select>
          <TextField 
            value={address}
            variant='filled'
            error={showAddressValid && !addressValid}
            helperText={!showAddressValid || addressValid ? null : 'Address invalid'}
            onChange={(e) => setAddress(e.target.value)}
            onBlur={() => setShowAddressValid(true)}
          />
          <IconButton disabled={!address || !addressValid} onClick={() => onAddressEntered(address)}>
            <ArrowForwardIcon />
          </IconButton>
        </Flex>
      }
      onSubmit={() => {
        if (!address || !addressValid) {
          return;
        }

        onAddressEntered(address)
      }}
    />
  );
}

function CreateNewStep({ notificationConfig, onCreated }: { notificationConfig: NotificationConfig, onCreated: () => void }) {
  useEffect(() => {
    const enable = async () => {
      await enableNotifications(notificationConfig);
      onCreated();
    };
    
    enable();
  }, [notificationConfig, onCreated]);

  return (
    <CircularProgress color='info'/>
  );
}

function AwaitVerificationStep({ address }: { address: string }) {
  return (
    <Step 
      description={`A verification code has been sent to ${address}`}
      body={null}
    />
  );
}

enum ConfigurationSteps {
  ENTER_CONTACT,
  EDIT_EXISTING,
  CHOOSE_MODEL,
  CHOOSE_COLOR,
  CHOOSE_STORAGE,
  ENTER_ADDRESS,
  CREATE_NEW,
  AWAIT_VERIFICATION
}
function useSteps(existingAddress: string | null) {
  const [step, setStep] = useState(ConfigurationSteps.ENTER_CONTACT);
  const [contactInfo, setContactInfo] = useState<{ id: string, type: ContactType } | undefined>(undefined);
  const [model, setModel] = useState<PhoneModel | undefined>(undefined);
  const [color, setColor] = useState<PhoneColor | undefined>(undefined);
  const [storage, setStorage] = useState<number | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!existingAddress) {
      return;
    }

    setContactInfo({
      id: existingAddress,
      type: PHONE_NUMBER_REGEX.test(existingAddress) ? ContactType.SMS : ContactType.EMAIL
    });
    setStep(ConfigurationSteps.EDIT_EXISTING);
  }, [existingAddress]);

  switch (step) {
    case ConfigurationSteps.ENTER_CONTACT: 
      return (
        <EnterContactStep 
          onNotificationTypeSpecified={(notificationTypeInfo) => {
            setContactInfo(notificationTypeInfo);
            setStep(ConfigurationSteps.EDIT_EXISTING);
          }} 
        />
      );
    case ConfigurationSteps.EDIT_EXISTING:
      return (
        <EditExistingStep
          contactAddress={contactInfo!.id}
          type={contactInfo!.type}
          onExistingNotFound={() => setStep(ConfigurationSteps.CHOOSE_MODEL)}
        />
      );
    case ConfigurationSteps.CHOOSE_MODEL:
      return (
        <ChooseModelStep 
          onModelSelected={(model) => {
            setModel(model);
            setStep(ConfigurationSteps.CHOOSE_COLOR)
          }}
        />
      );
    case ConfigurationSteps.CHOOSE_COLOR:
      return (
        <ChooseColorStep 
          model={model!}
          onColorSelected={(color) => {
            setColor(color);
            setStep(ConfigurationSteps.CHOOSE_STORAGE);
          }}
        />
      );
    case ConfigurationSteps.CHOOSE_STORAGE:
      return (
        <ChooseStorageStep 
          model={model!}
          onStorageSelected={(storage) => {
            setStorage(storage);
            setStep(ConfigurationSteps.ENTER_ADDRESS);
          }}
        />
      );
    case ConfigurationSteps.ENTER_ADDRESS:
      return (
        <EnterAddressStep 
          onAddressEntered={(address) => {
            setAddress(address);
            setStep(ConfigurationSteps.CREATE_NEW)
          }}
        />
      );
    case ConfigurationSteps.CREATE_NEW:
      return (
        <CreateNewStep 
          notificationConfig={{
            contactType: contactInfo!.type,
            contactAddress: contactInfo!.id,
            product: {
              model: model!,
              color: color!,
              storage: storage!
            },
            address: address!
          }}
          onCreated={() => setStep(ConfigurationSteps.AWAIT_VERIFICATION)}
        />
      );
    case ConfigurationSteps.AWAIT_VERIFICATION:
      return (
        <AwaitVerificationStep
          address={address!}
        />
      );
  }

  return null;
}

function ConfigurationForm() {
  const [params] = useSearchParams();
  
  const existingAddress = params.get('address');
  const stepContent = useSteps(existingAddress);

  return (
    <ConfigurationStep>
      { stepContent }
    </ConfigurationStep>
  );
}

export default ConfigurationForm;

// 0. Enter phone number or email
// 1. What device are you looking for
// 2. Where do you live?