import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import { ThemeProvider } from '@mui/system';

import styled from 'styled-components';

import THEME from './theme';
import Verification from './Verification';
import NotFound from './404';
import ConfigurationForm from './ConfigurationForm';

const Shapes = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  height: 100vh;
`;
const Header = styled.header`
  display: flex;
  justify-content: end;
  margin: 16px;
  flex: 0;

  > h1 {
    margin: 0;
    position: relative;

    > .iphone {
      color: black;
    }

    > .inventory {
      color: white;
    }
  }
`;

const Body = styled.main`
  position: relative;
  min-width: 100vw;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Shapes>
        <svg viewBox='0 0 100 100' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'>
          <defs>
            <linearGradient id='gradient1'>
              <stop offset='0%' stopColor='#2ca2b4' />
              <stop offset='25%' stopColor='#5598de' />
              <stop offset='50%' stopColor='#7f87ff' />
            </linearGradient>
            <linearGradient id='gradient2'>
              <stop offset='50%' stopColor='#7f87ff' />
              <stop offset='75%' stopColor='#f65aad' />
              <stop offset='100%' stopColor='#ec3d43' />
            </linearGradient>
          </defs>
          <ellipse cx='25' cy='125' rx='120' ry='110' fill='url(#gradient2)' fillOpacity='0.75' />
          <ellipse cx='75' cy='-25' rx='120' ry='110' fill='url(#gradient1)' fillOpacity='0.75' />
        </svg>
      </Shapes>
      <Page>
        <Header>
          <h1><span className='iphone'>iPhone</span><span className='inventory'>Inventory</span></h1>
        </Header>
        <Body>
          <Router>
            <Routes>
              <Route path='/verify' element={<Verification />} />
              <Route path='/' element={<ConfigurationForm />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Router>
        </Body>
      </Page>
    </ThemeProvider>
  );
}

export default App;
